/* eslint-disable no-unused-vars */
import React, { lazy, Suspense, useContext } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom'; // Redirect
import DockerComponent from '../../../../pages/post-auth/home/docker/docker.component';
import { setMainContentScrollData } from '../../../../redux/app/app.actions';
import InLoader from '../../loaders/component-loader/component-loader.component';
import './main-content.styles.scss';
import commentNotificationsComponent from '../../../../pages/post-auth/home/notification-logs/comment-notifications/comment-notifications.component';
import { DataContext } from '../content.component';
import SidePanel from '../../side-panel/side-panel';

const RecentFiles = lazy(() => import('../../../../pages/post-auth/home/recent-files/recent-files.component'));
const CollectionPage = lazy(() => import('../../../../pages/post-auth/home/collection/collection.component'));
const AdminPage = lazy(() => import('../../../../pages/post-auth/home/admin/admin.component'));
const NewCollectionPage = lazy(() => import('../../../../pages/post-auth/home/collection/new-collection/new-collection.component'));
const AnalysesPage = lazy(() => import('../../../../pages/post-auth/home/analyses/analyses.component'));
const AddNewAnalysis = lazy(() => import('../../../../pages/post-auth/home/analyses/new-analysis/new-analysis.component'));
const FolderListing = lazy(() => import('../../../../pages/post-auth/home/folder-listing/folder-listing.component'));
const Resources = lazy(() => import('../../../../pages/post-auth/home/resources/resources.components'));
const GeneSetTable = lazy(() => import('../../../../pages/post-auth/home/resources/gene-set/gene-set.component'));
const AnalyteMapping = lazy(() => import('../../../../pages/post-auth/home/resources/analyte-mapping/analyte-mapping.component'));
const MappingTables = lazy(() => import('../../../../pages/post-auth/home/resources/analyte-mapping/mapping-tables/mapping-tables.component'));
const AdvancedSearch = lazy(() => import('../../../../pages/post-auth/home/advanced-search/advanced-search.component'));
const GlobalSearchResults = lazy(() => import('../../../../pages/post-auth/home/global-search-results/global-search-results.component'));
const SearchNotifications = lazy(() => import('../../../../pages/post-auth/home/notification-logs/search-notifications/search-notifications.component'));
const DownloadNotifications = lazy(() => import('../../../../pages/post-auth/home/notification-logs/download-notifications/download-notifications.component'));
const About = lazy(() => import('../../../../pages/post-auth/home/about/about.component'));
const Help = lazy(() => import('../../../../pages/post-auth/home/help/help.component'));
const TermsOfService = lazy(() => import('../../../../pages/post-auth/home/tos/tos.component'));
const CommentNotifications = lazy(() => import('../../../../pages/post-auth/home/notification-logs/comment-notifications/comment-notifications.component'));
const MainContent = (props) => {
    const {
        fullScreen = false,
        setMainContentScrollData,
        location
    } = props;

    const { isSidePanelOpen,setIsSidePanelOpen, rowData } = useContext(DataContext);
    const scrollEvent = (event) => {
        if (
            location.pathname.includes('network') ||
            location.pathname.includes('files') ||
            location.pathname.includes('admin')
        ) {
            setMainContentScrollData(event);
        }
    }

    return (
        <div className={`main-content ${fullScreen ? 'full-screen' : 'full-width'}`}
            id={'main_content'}
            onScroll={(event) => scrollEvent(event)}>
            <Suspense fallback={<InLoader loaderText={'Loading...'} />}>
                <Switch>
                    <Route
                        exact
                        path="/home"
                        render={() => {
                            return <Redirect to='/home/recent-files' />
                        }} />
                    <Route path='/home/notifications' component={SearchNotifications} />
                    <Route path='/home/downloads' component={DownloadNotifications} />
                    <Route path='/home/comment-notifications' component={CommentNotifications} />
                    <Route path='/home/recent-files' component={RecentFiles} />
                    <Route path='/home/collections/:type' component={CollectionPage} />
                    <Route path='/home/admin' component={AdminPage} />
                    <Route path='/home/add-new-collection' component={NewCollectionPage} />
                    <Route path='/home/analyses/:type' component={AnalysesPage} />
                    <Route path='/home/add-new-analysis' component={AddNewAnalysis} />
                    <Route path='/home/data-listing' component={FolderListing} />
                    <Route path='/home/global-search-results' component={GlobalSearchResults} />
                    <Route exact path='/home/resources' component={Resources} />
                    <Route path='/home/resources/gene-set' component={GeneSetTable} />
                    <Route path='/home/resources/analyte-mapping/:gridType/:routeTitle' component={AnalyteMapping} />
                    <Route path='/home/resources/mapping-tables/:gridType/:routeTitle' component={MappingTables} />
                    <Route path='/home/advanced-search' component={AdvancedSearch} />
                    <Route path='/home/docker' component={DockerComponent} />
                    <Route path='/home/help' component={Help} />
                    <Route path='/home/about' component={About} />
                    <Route path='/home/termsofservice' component={TermsOfService} />

                </Switch>
            </Suspense>
            {/* {isSidePanelOpen && ( */}
            <SidePanel setIsSidePanelOpen={setIsSidePanelOpen} isSidePanelOpen={isSidePanelOpen} {...props} data={rowData} />
        {/* )} */}
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    setMainContentScrollData: (scrollData) => dispatch(setMainContentScrollData(scrollData))
});

export default connect(null, mapDispatchToProps)(React.memo(MainContent));