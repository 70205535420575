/* eslint-disable no-unused-vars */
import axios from 'axios';
import SecurityService from '../services/security-service';
import AppService from '../services/app-service';
import { CONST } from '../utils/omi-constants';
import { COMPONENT_APIS } from '../utils/omi-api';
import { store } from '../../redux/store.redux';
import { setLoader } from '../../redux/app/app.actions';
import { setModalData } from '../../redux/modal/modal.actions';

let csrftoken = readCookie('csrftoken');
const slashRetainedUrlList = [
    '?search=',
    'aws-temp-credentials',
    'user-register',
    'user-login'
]; // [ 'data-collections/sample-attributes' ];

// AXIOS : INSTANCE WITH NO AUTH ---------------------------------------
export const ucsfLoginInstance = axios.create({
    baseURL: CONST.END_POINT,
    headers: {
        'X-CSRFToken': csrftoken
    }
});

// AXIOS : INSTANCE WITH AUTH ---------------------------------------
export const ucsfInstance = axios.create({
    baseURL: CONST.END_POINT
});
ucsfInstance.defaults.headers.common['Authorization'] = SecurityService.getSecurityToken();

// CSRF TOKEN COOKIE READER -----------------------------------------
function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

// INTERCEPTOR : REQUEST ---------------------------------------
ucsfInstance.interceptors.request.use(request => {
    const URL_BUCKET = slashRetainedUrlList.filter(url => request.url.includes(url));
    if (!URL_BUCKET.length) {
        if(!request.url.includes('file-comments')){    // need slash for folders but not for files api call
            if (request.url.substr(-1) !== '/') {
                request.url = request.url + '/';
            }
        }
    }
    request.headers['version'] = CONST.OMICON_VERSION;
    if (SecurityService.getSecurityToken()) {
        request.headers['Authorization'] = `Token ${SecurityService.getSecurityToken()}`;
    }
    return request;
}, error => {
    return Promise.reject(error);
});
// -------------------------------------------------------------

// INTERCEPTOR : RESPONSE --------------------------------------
ucsfInstance.interceptors.response.use(res => {
    const { data } = res;
    if (data && data['status'] && data['status'] === 500) {
        AppService.setNotification({
            title: 'Error',
            message: data['message'],
            type: 'danger'
        });
        return Promise.reject(new Error('Internal Server Error'));
    } else if (data && data['status'] && data['status'] === 400) {
        if (data['detail'] && data['detail'].toLowerCase() === "invalid api version.") {
            let currentUser = store.getState().user.currentUser;
            let message = "You need to update the app by logging out. Please login again to continue";
            let btnText = "Logout";
            if (!currentUser) {
                message = "New version is available. Please refresh the application to continue";
                btnText = "Refresh";
            }
            store.dispatch(setLoader(false));
            store.dispatch(setModalData({
                type: 'version-update',
                data: {
                    title: "Version Update Available",
                    message,
                    btnText
                }
            }));
            return Promise.reject(new Error('Version Mismatch'));
        }
    }
    return Promise.resolve(res);
}, error => {
    if (error && error?.message === 'Network Error' && AppService.networkErrorCount === 0) {
        AppService.networkErrorCount += 1;
        store?.dispatch(setLoader(false));
        AppService.setNotification({
            title: error?.message,
            message: 'Network Failure. Try reloading the application or reconnect to the VPN.',
            type: 'danger'
        });
    } else {
        AppService.networkErrorCount = 0;
        const { response } = error || {};
        let { data } = response || {};
        if (data && data['detail']) {
            accessCheck(data);
        }
        return Promise.reject(data);
    }
    return Promise.reject(error);
});
// -------------------------------------------------------------

const accessCheck = (data) => {
    if (data && (data['detail'] === 'Invalid token.' || data['detail'].includes('Invalid token'))) {
        SecurityService.removeSecurityToken();
        window.open(`${CONST.END_POINT}${COMPONENT_APIS.APP_SHIBBOLETH_LOGOUT}`, 'Shibboleth_SSO_Logout');
    } else if (data && data['detail']) {
        AppService.setNotification({
            title: '!Permission Alert',
            message: data['detail'],
            type: 'warning'
        });
    }
}