import React, { useEffect, useState } from 'react';
import CommentsPage from './comments.components';
import AppService from '../../../core/services/app-service';

import './side-panel.scss';

const SidePanel = (props) => {
    const { isSidePanelOpen, setIsSidePanelOpen } = props;
    const [isOpen, setIsOpen] = useState(false);
    const queryParams = AppService.fetchQueryParams(props?.location?.search);
    const file_folder_name = queryParams?.file_folder_name;
    useEffect(() => {
        file_folder_name && setIsOpen(true);
    }, [file_folder_name])

    return (
        <div className={`right-side-panel ${isSidePanelOpen || isOpen ? 'open' : ''}`}>
            <div className='header-container'>
                <span className='close-btn' onClick={() => {setIsSidePanelOpen(false); setIsOpen(false);}}></span>
            </div>
            <CommentsPage {...props} />
        </div>
    );
};

export default SidePanel;
