// import { sampleSearchQueries } from "../../pages/post-auth/home/advanced-search/data/advanced-search.data";

export const GRID_HEADERS = {
    'ontologies': [{
        name: 'Name',
        sortKey: 'name',
        isSortable: true
    }],
    'attributeTables': [{
        name: 'Name',
        sortKey: 'name',
        isSortable: true
    }, {
        name: 'Size',
        sortSubKey: 'history',
        sortKey: 'sizeByteVal',
        isSortable: true
    }, {
        name: 'Version',
        sortSubKey: 'history',
        sortKey: 'version',
        isSortable: true
    }, {
        name: 'Date Modified',
        sortSubKey: 'history',
        sortKey: 'date_modified', // 'date_modified',
        isSortable: true
    }],
    'analyteMappingTables': [{
        name: 'Name',
        isSortable: true
    }],
    'files': [{
        name: 'Type',
        sortKey: 'type',
        isSortable: true
    }, {
        name: 'Name',
        sortKey: 'name',
        isSortable: true
    }, {
        name: 'Availability',
        sortKey: 'visibilityDisplayName', // 'visibility',
        isSortable: true
    }, {
        name: 'Owner',
        sortKey: 'owner',
        isSortable: true
    }, {
        name: 'Date Last Accessed',
        sortKey: 'uploaded_on',
        isSortable: true
    }, {
        name: 'Size',
        sortKey: 'sizeByteVal',
        isSortable: true
    },{
        name: '',
        sortKey: 'viewComment',
        isSortable: false
    }, {
        name: '',
        isSortable: false
    }],
    'fileTypeResults': [{
        name: 'Type',
        sortKey: 'type',
        isSortable: true
    }, {
        name: 'Name',
        sortKey: 'name',
        isSortable: true
    }, {
        name: 'Availability',
        sortKey: 'visibilityDisplayName',
        isSortable: true
    }, {
        name: 'Owner',
        sortKey: 'owner',
        isSortable: true
    }, {
        name: 'Date Last Accessed',
        sortKey: 'uploaded_on',
        isSortable: true
    }, {
        name: 'Size',
        sortKey: 'sizeByteVal',
        isSortable: true
    }, {
        name: '',
        isSortable: false
    }],
    'globalSearchFileTypeResults': [{
        name: 'Type',
        sortKey: 'type',
        isSortable: true
    }, {
        name: 'Name',
        sortKey: 'name',
        isSortable: true
    }, {
        name: 'Availability',
        sortKey: 'visibilityDisplayName',
        isSortable: true
    }, {
        name: 'Location',
        sortKey: 'location',
        isSortable: true
    }, {
        name: 'Owner',
        sortKey: 'owner',
        isSortable: true
    }, {
        name: 'Date Last Accessed',
        sortKey: 'uploaded_on',
        isSortable: true
    }, {
        name: 'Size',
        sortKey: 'sizeByteVal',
        isSortable: true
    }, {
        name: '',
        isSortable: false
    }],
    'recentFiles': [{
        name: 'Type',
        sortKey: 'type',
        isSortable: true
    }, {
        name: 'Name',
        sortKey: 'name',
        isSortable: true
    }, {
        name: 'Availability',
        sortKey: 'visibility', // 'visibilityDisplayName', // 'visibility',
        isSortable: true
    }, {
        name: 'Owner',
        sortKey: 'owner',
        isSortable: true
    }, {
        name: 'Date Last Accessed',
        sortKey: 'upload_date', // 'uploaded_on',
        isSortable: true
    }, {
        name: 'Size',
        sortKey: 'sizeByteVal',
        isSortable: true
    }, /* {
        name: '',
        isSortable: false
    }, */
    {
        name: '',
        isSortable: false
    }],
    'dsFiles': [{
        name: 'Platform',
        sortKey: 'platform',
        isSortable: true
    }, {
        name: 'Dataset Version',
        sortKey: 'ds_version',
        isSortable: true
    }, {
        name: 'Upload Date',
        sortKey: 'upload_date',
        isSortable: true
    }, {
        name: 'Transformations',
        sortKey: 'transformations',
        isSortable: true
    }, {
        name: 'Quantification Scale',
        sortKey: 'quantification_scale',
        isSortable: true
    }, {
        name: 'Number of Samples',
        sortKey: 'number_of_samples',
        isSortable: true
    }],
    'geneSetTables': [{
        name: 'Name',
        isSortable: true
    }],
    'sampleAttributes': [{
        name: 'Attribute', // 'Sample Attribute',
        isSortable: false
    }, {
        name: 'Attribute Type',
        isSortable: false
    }, {
        name: 'Enumerated Values', // 'Possible Values',
        isSortable: false
    }, {
        name: 'Example',
        isSortable: false
    }, {
        name: 'Required?',
        isSortable: false
    }, {
        name: 'Indexed?',
        isSortable: false
    }, {
        name: 'Data Source', // 'Possible Values',
        isSortable: false
    }, {
        name: 'Description',
        isSortable: false
    }, {
        name: '',
        isSortable: false
    }],
    'dataCollectionAttributes': [{
        name: 'Attribute', // 'Sample Attribute',
        isSortable: false
    }, {
        name: 'Attribute Type',
        isSortable: false
    }, {
        name: 'Enumerated Values', // 'Possible Values',
        isSortable: false
    }, {
        name: 'Example',
        isSortable: false
    }, {
        name: 'Required?',
        isSortable: false
    }, {
        name: 'Indexed?',
        isSortable: false
    }, {
        name: 'Data Source', // 'Possible Values',
        isSortable: false
    }, {
        name: 'Description',
        isSortable: false
    }],
    'dataSetAttributes': [{
        name: 'Attribute', // 'Sample Attribute',
        isSortable: false
    }, {
        name: 'Attribute Type',
        isSortable: false
    }, {
        name: 'Enumerated Values', // 'Possible Values',
        isSortable: false
    }, {
        name: 'Example',
        isSortable: false
    }, {
        name: 'Required?',
        isSortable: false
    }, {
        name: 'Indexed?',
        isSortable: false
    }, {
        name: 'Data Source', // 'Possible Values',
        isSortable: false
    }, {
        name: 'Description',
        isSortable: false
    }],
    'analysisAttributes': [{
        name: 'Attribute', // 'Sample Attribute',
        isSortable: false
    }, {
        name: 'Attribute Type',
        isSortable: false
    }, {
        name: 'Enumerated Values', // 'Possible Values',
        isSortable: false
    }, {
        name: 'Example',
        isSortable: false
    }, {
        name: 'Required?',
        isSortable: false
    }, {
        name: 'Indexed?',
        isSortable: false
    }, {
        name: 'Data Source', // 'Possible Values',
        isSortable: false
    }, {
        name: 'Description',
        isSortable: false
    }],
    'microArray': [{
        name: 'Name',
        sortKey: 'name',
        isSortable: true
    }, /* {
        name: 'Version',
        sortKey: 'version',
        isSortable: true
    },  */{
        name: 'Platform',
        sortKey: 'platform',
        isSortable: true
    }, {
        name: 'Organism',
        sortKey: 'organism',
        isSortable: true
    }, {
        name: 'Key',
        sortKey: 'unique_identifier',
        isSortable: true
    }, {
        name: 'Date Last Accessed',
        sortKey: 'date_uploaded',
        isSortable: true
    }, {
        name: '',
        isSortable: false
    }],
    'nonMicroArray': [{
        name: 'Name',
        sortKey: 'name',
        isSortable: true
    }, /* {
        name: 'Version',
        sortKey: 'version',
        isSortable: true
    },  */{
        name: 'Organism',
        sortKey: 'organism',
        isSortable: true
    }, {
        name: 'Key',
        sortKey: 'unique_identifier',
        isSortable: true
    }, {
        name: 'Date Last Accessed',
        sortKey: 'date_uploaded',
        isSortable: true
    }, {
        name: '',
        isSortable: false
    }],
    'mappingTables': [{
        name: 'Name',
        sortKey: 'name',
        isSortable: true
    },
    /* , {
        name: 'Version',
        sortKey: 'version',
        isSortable: true
    }, */
    {
        name: 'Key',
        sortKey: 'unique_identifier',
        isSortable: true
    }, {
        name: 'Value',
        sortKey: 'value',
        isSortable: true
    }, {
        name: 'Date Last Accessed',
        sortKey: 'date_uploaded',
        isSortable: true
    }, {
        name: '',
        isSortable: false
    }],
    'geneSet': [{
        name: 'Omicon ID',
        sortKey: 'Omicon ID',
        isSortable: true
    }, {
        name: 'Set ID',
        sortKey: 'Set ID',
        isSortable: true
    }, {
        name: 'Set Name',
        sortKey: 'Set Name',
        isSortable: true
    }, {
        name: 'Set Source',
        sortKey: 'Set Source',
        isSortable: true
    }, {
        name: 'Category',
        sortKey: 'Set Category',
        isSortable: true
    }, {
        name: 'Set Size',
        sortKey: 'Set Size',
        isSortable: true
    }, {
        name: 'Species',
        sortKey: 'Set Species',
        isSortable: true
    }, {
        name: 'PubMed',
        sortKey: 'PubMed ID',
        isSortable: true
    }, {
        name: 'Description',
        sortKey: 'Description',
        isSortable: false
    }, {
        name: 'Date Last Accessed',
        sortKey: 'Date Updated',
        isSortable: false
    }, {
        name: 'Uploaded By',
        sortKey: 'Uploaded By',
        isSortable: false
    }],
    'searchNotifications': [{
        name: 'Type',
        sortKey: 'type',
        isSortable: false
    }, {
        name: 'Description',
        sortKey: 'Description',
        isSortable: false
    }, {
        name: 'Date Time',
        sortKey: 'created_at',
        isSortable: false
    }],
    'downloadNotifications': [{
        name: 'Download',
        sortKey: 'type',
        isSortable: false
    },{
        name: 'Name',
        sortKey: 'name',
        isSortable: false
    }, {
        name: 'Size',
        sortKey: 'file_size',
        isSortable: false
    }, {
        name: 'Date Time',
        sortKey: 'created_at',
        isSortable: false
    }],
    'commentNotifications': [{
        name: 'Comment',
        sortKey: 'comment_details',
        isSortable: false
    }, {
        name: 'Mentioned by',
        sortKey: 'mentioned_by',
        isSortable: false
    }, {
        name: 'Date and Time',
        sortKey: 'created_at',
        isSortable: false
    }],
    'analysisFiles': [{
        name: 'Title',
        sortKey: 'title',
        isSortable: true
    }, {
        name: 'Owner',
        sortKey: 'owner',
        isSortable: true
    }, {
        name: 'Upload Date',
        sortKey: 'upload_date',
        isSortable: true
    }],
    'userManagement': [{
        name: 'First Name',
        sortKey: 'first_name',
        isSortable: true
    }, {
        name: 'Last Name',
        sortKey: 'last_name',
        isSortable: true
    }, {
        name: 'Username',
        sortKey: 'username',
        isSortable: true
    }, {
        name: 'Email',
        sortKey: 'email',
        isSortable: true
    }, {
        name: 'Institution',
        sortKey: 'institution',
        isSortable: true
    }, {
        name: 'ORCID ID',
        sortKey: 'orcid_id',
        isSortable: true
    }, 
     {
        name: 'Last login date',
        sortKey: 'last_login_date',
        isSortable: true
    }, {
        name: 'Date registered',
        sortKey: 'date_registered',
        isSortable: true
    },
     {
        name: 'TOS accepted',
        sortKey: 'tos_accepted',
        isSortable: false
    },
     {
        name: '',
        isSortable: false
    }],
    'userManagementForLab': [{
        name: 'User',
        sortKey: 'name',
        isSortable: true
    }, {
        name: 'Email',
        sortKey: 'email',
        isSortable: true
    }, {
        name: 'Role',
        sortKey: 'visibilityDisplayName', // 'visibility',
        isSortable: true
    }, {
        name: 'TOS accepted',
        sortKey: 'tos_accepted',
        isSortable: false
    },{
        name: '',
        isSortable: false
    }],
    'recentSearchQueries': [
        {
            name: 'SlNo',
            sortKey: 'index',
            isSortable: true
        }, {
            name: 'Query Link',
            sortKey: 'searchLink',
            isSortable: false
        }, {
            name: 'Created By',
            sortKey: 'createdBy',
            isSortable: true
        }, {
            name: 'Date Added',
            sortKey: 'createdAt',
            isSortable: true
        }, {
            name: 'Execution Time',
            sortKey: 'executionTime',
            isSortable: true
        }, {
            name: 'Result Type',
            sortKey: 'resultType',
            isSortable: true
        }
    ],
    'sampleSearchQueries': [
        {
            name: 'SlNo',
            sortKey: 'index',
            isSortable: true
        }, {
            name: 'Query Description',
            sortKey: 'description',
            isSortable: true
        }, {
            name: 'Query Link',
            sortKey: 'searchLink',
            isSortable: false
        }, {
            name: 'Created By',
            sortKey: 'createdBy',
            isSortable: true
        }
    ],
    'savedSearchQueries': [
        {
            name: 'SlNo',
            sortKey: 'index',
            isSortable: true
        }, {
            name: 'Query Description',
            sortKey: 'description',
            isSortable: true
        }, {
            name: 'Query Link',
            sortKey: 'searchLink',
            isSortable: false
        }, {
            name: 'Created By',
            sortKey: 'createdBy',
            isSortable: true
        }, {
            name: 'Date Added',
            sortKey: 'createdAt',
            isSortable: true
        }, {
            name: 'Execution Time',
            sortKey: 'executionTime',
            isSortable: true
        }
    ]
};

export const GRID_KEY_DATA = {
    'files': [{
        key: 'checked',
        typeKey: 'checkbox'
    }, {
        key: 'type'
    }, {
        key: 'name',
        typeKey: 'fileType'
    }, {
        key: 'visibilityDisplayName' // 'visibility'
    }, {
        key: 'ownerImg',
        typeKey: 'img',
        hoverVal: 'owner'
    }, {
        key: 'uploaded_on'
    }, {
        key: 'sizeDisplayName' // size
    }, {
        key: 'viewComment',
        typeKey: 'img',
        hoverVal: ''
    },{
        key: 'more',
        typeKey: 'more'
    }],
    'fileTypeResults': [{
        key: 'type'
    }, {
        key: 'name',
        typeKey: 'fileType'
    }, {
        key: 'visibilityDisplayName' // 'visibility'
    }, {
        key: 'ownerImg',
        typeKey: 'img',
        hoverVal: 'owner'
    }, {
        key: 'uploaded_on'
    }, {
        key: 'sizeDisplayName' // size
    }, {
        key: 'more',
        typeKey: 'more'
    }],
    'globalSearchFileTypeResults': [{
        key: 'type'
    }, {
        key: 'name',
        typeKey: 'fileType'
    }, {
        key: 'visibilityDisplayName' // 'visibility'
    }, {
        key: 'location'
    }, {
        key: 'ownerImg',
        typeKey: 'img',
        hoverVal: 'owner'
    }, {
        key: 'uploaded_on'
    }, {
        key: 'sizeDisplayName' // size
    }, {
        key: 'more',
        typeKey: 'more'
    }],
    'recentFiles': [{
        key: 'checked',
        typeKey: 'checkbox'
    }, {
        key: 'type'
    }, {
        key: 'name',
        typeKey: 'fileType'
    }, {
        key: 'visibility'
    }, {
        key: 'owner',
        typeKey: 'img'
    }, {
        key: 'upload_date'
    }, {
        key: 'size'
    }, /* {
        key: 'comments',
        typeKey: 'comments'
    }, */
    {
        key: 'more',
        typeKey: 'more'
    }],
    'dsFiles': [{
        key: 'platform'
    }, {
        key: 'ds_version',
    }, {
        key: 'upload_date',
    }, {
        key: 'transformations',
    }, {
        key: 'quantification_scale',
    }, {
        key: 'number_of_samples'
    }],
    'ontologies': [{
        key: 'name',
        typeKey: 'link'
    }],
    'attributeTables': [{
        key: 'name',
        typeKey: 'link'
    }, {
        key: 'sizeDisplayName',
        subKey: 'history',
        typeKey: 'history_array'
    }, {
        key: 'version',
        subKey: 'history',
        typeKey: 'history_array'
    }, {
        key: 'date_modified',
        subKey: 'history',
        typeKey: 'history_array'
    }],
    'analyteMappingTables': [{
        key: 'name',
        typeKey: 'link'
    }],
    'geneSetTables': [{
        key: 'name',
        typeKey: 'link'
    }],
    'sampleAttributes': [{
        key: 'name' // 'sample_attribute'
    }, {
        key: 'attribute_type'
    }, {
        key: 'possible_values',
        typeKey: 'stringArray'
    }, {
        key: 'examples'
    }, {
        key: 'is_required',
        typeKey: 'answer'
    }, {
        key: 'is_indexed',
        typeKey: 'answer'
    }, {
        key: 'data_source'
    }, {
        key: 'description'
    }, {
        key: 'more',
        typeKey: 'more'
    }],
    'dataCollectionAttributes': [{
        key: 'name' // 'sample_attribute'
    }, {
        key: 'attribute_type'
    }, {
        key: 'possible_values',
        typeKey: 'stringArray'
    }, {
        key: 'examples'
    }, {
        key: 'is_required',
        typeKey: 'answer'
    }, {
        key: 'is_indexed',
        typeKey: 'answer'
    }, {
        key: 'data_source'
    }, {
        key: 'description'
    }],
    'dataSetAttributes': [{
        key: 'name' // 'sample_attribute'
    }, {
        key: 'attribute_type'
    }, {
        key: 'possible_values',
        typeKey: 'stringArray'
    }, {
        key: 'examples'
    }, {
        key: 'is_required',
        typeKey: 'answer'
    }, {
        key: 'is_indexed',
        typeKey: 'answer'
    }, {
        key: 'data_source'
    }, {
        key: 'description'
    }],
    'analysisAttributes': [{
        key: 'name' // 'sample_attribute'
    }, {
        key: 'attribute_type'
    }, {
        key: 'possible_values',
        typeKey: 'stringArray'
    }, {
        key: 'examples'
    }, {
        key: 'is_required',
        typeKey: 'answer'
    }, {
        key: 'is_indexed',
        typeKey: 'answer'
    }, {
        key: 'data_source'
    }, {
        key: 'description'
    }],
    'microArray': [{
        key: 'name', // 'sample_attribute'
        typeKey: 'link'
    }, /* {
        key: 'version'
    },  */{
        key: 'platform'
    }, {
        key: 'organism'
    }, {
        key: 'unique_identifier'
    }, {
        key: 'date_uploaded'
    }, {
        key: 'download',
        typeKey: 'download'
    }],
    'nonMicroArray': [{
        key: 'name', // 'sample_attribute'
        typeKey: 'link'
    }, /* {
        key: 'version'
    },  */{
        key: 'organism'
    }, {
        key: 'unique_identifier'
    }, {
        key: 'date_uploaded'
    }, {
        key: 'download',
        typeKey: 'download'
    }],
    'mappingTables': [{
        key: 'name',
        typeKey: 'link'
    }, /* {
        key: 'version'
    },  */{
        key: 'unique_identifier'
    }, {
        key: 'value'
    }, {
        key: 'date_uploaded'
    }, {
        key: 'download',
        typeKey: 'download'
    }],
    'geneSet': [{
        key: 'Omicon ID'
    }, {
        key: 'Set ID',
        typeKey: 'nameAndDownload'
    }, {
        key: 'Set Name'
    }, {
        key: 'Set Source'
    }, {
        key: 'Set Category'
    }, {
        key: 'Set Size'
    }, {
        key: 'Set Species'
    }, {
        key: 'PubMed ID'
    }, {
        key: 'Description'
    }, {
        key: 'Date Updated'
    }, {
        key: 'Uploaded By'
    }],
    'analysisFiles': [{
        key: 'title'
    }, {
        key: 'owner'
    }, {
        key: 'upload_date'
    }],
    'userManagement': [{
        key: 'first_name'
    }, {
        key: 'last_name'
    }, {
        key: 'username'
    }, {
        key: 'email'
    }, {
        key: 'institution'
    }, {
        key: 'orcid_id'
    },
    {
        key: 'last_login_date'
    }, {
        key: 'date_registered'
    },
     {
        key: 'tos_accepted'
    }, 
     {
        key: 'more',
        typeKey: 'more'
    }],
    'userManagementForLab': [{
        key: 'name'
    }, {
        key: 'email'
    }, {
        key: 'visibilityDisplayName',
    },{
        key: 'tos_accepted',
    },{
        key: 'more',
        typeKey: 'more'
    }],
    'recentSearchQueries': [{
        key: 'rId'
    }, {
        key: 'searchLink',
        typeKey: 'linkAndCopy'
    }, {
        key: 'createdBy'
    }, {
        key: 'createdAt'
    }, {
        key: 'executionTime'
    }, {
        key: 'resultType'
    }],
    'sampleSearchQueries': [{
        key: 'qId'
    }, {
        key: 'description'
    }, {
        key: 'searchLink',
        typeKey: 'linkAndCopy'
    }, {
        key: 'createdBy'
    }],
    'savedSearchQueries': [{
        key: 'uId'
    }, {
        key: 'description'
    }, {
        key: 'searchLink',
        typeKey: 'linkAndCopy'
    }, {
        key: 'createdBy'
    }, {
        key: 'createdAt'
    }, {
        key: 'executionTime'
    }],
    'searchNotifications': [{
        key: 'type',
        typeKey: 'linkWithOnClick'
    },
    {
        key: 'Description'
    },
    {
        key: 'timestamp'

    }],
    'commentNotifications': [{
        key: 'comment_details',
        typeKey: 'linkWithOnClick'
    },
    {
        key: 'mentioned_by'
    },
    {
        key: 'created_at'

    }],
    'downloadNotifications': [{
        key: 'type',
        typeKey: 'linkWithOnClick'
    },{
        key: 'name'
    },
    {
        key: 'file_size'
    },
    {
        key: 'created_at'

    }]
};

export const GRID_DATA = {
    'filesBACKUP': [
        { id: 101, name: 'Dataset 1', fileType: 'folder', type: 'Dataset', visibility: 'External', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 102, name: 'Dataset 2', fileType: 'folder', type: 'Dataset', visibility: 'External', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 103, name: 'Documentation', fileType: 'folder', type: 'Dataset', visibility: 'External', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 104, name: 'Documentation', fileType: 'folder', type: 'Dataset', visibility: 'External', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 105, name: 'Documentation', fileType: 'folder', type: 'Dataset', visibility: 'External', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 106, name: 'Documentation', fileType: 'folder', type: 'Dataset', visibility: 'External', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 107, name: 'Documentation', fileType: 'folder', type: 'Dataset', visibility: 'External', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 108, name: 'Documentation', fileType: 'folder', type: 'Dataset', visibility: 'External', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 109, name: 'Documentation', fileType: 'folder', type: 'Dataset', visibility: 'External', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 110, name: 'Documentation', fileType: 'folder', type: 'Dataset', visibility: 'External', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 111, name: 'Documentation', fileType: 'folder', type: 'Dataset', visibility: 'External', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 112, name: 'Documentation', fileType: 'folder', type: 'Dataset', visibility: 'External', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 113, name: 'Documentation', fileType: 'folder', type: 'Dataset', visibility: 'External', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 114, name: 'Documentation', fileType: 'folder', type: 'Dataset', visibility: 'External', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 115, name: 'File Name', fileType: 'xls', type: 'Analyte Metadata', visibility: 'External', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' }
    ],
    'recentFiles': [
        { id: 101, name: 'Module Dataset', fileType: 'folder', type: 'Dataset', visibility: 'Lab', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 102, name: 'Module Eigengenes', fileType: 'pdf', type: 'Analyte Metadata', visibility: 'External', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 103, name: 'Module Eigengenes Correlations', fileType: 'pdf', type: 'Analyte Metadata', visibility: 'Public', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 104, name: 'Module Eigengenes Correllelogram', fileType: 'xls', type: 'Covariate Matrix', visibility: 'Admin', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 105, name: 'Module Snapshots', fileType: 'xls', type: 'Covariate Matrix', visibility: 'Lab', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 106, name: 'Module Statistics', fileType: 'xls', type: 'Covariate Matrix', visibility: 'Lab', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
        { id: 107, name: 'Module Dataset', fileType: 'xls', type: 'Sample Attributes', visibility: 'Lab', owner: 'user', upload_date: 'Nov 30, 2018', size: '483 KB', comments: '5' },
    ],
    'ontologies': [{
        id: 101,
        name: 'NCBITaxon Ontology',
        redirectionUrl: 'https://bioportal.bioontology.org/ontologies/NCBITAXON',
        history_BACK: [{
            version: '20200503-0519', downloadUrl: 'https://www.google.com', date_modified: 'May 3, 2020'
        }, {
            version: '20200503-0518', downloadUrl: 'https://www.google.com', date_modified: 'May 5, 2020'
        }, {
            version: '20191222-0722', downloadUrl: 'https://www.google.com', date_modified: 'May 6, 2020'
        }]
    }, {
        id: 102,
        name: 'Uber-anatomy (UBERON) Ontology',
        redirectionUrl: 'https://bioportal.bioontology.org/ontologies/UBERON',
        history_BACK: [{
            version: '20200705-1234', downloadUrl: 'https://www.google.com', date_modified: 'May 7, 2020'
        }, {
            version: '20200705-1235', downloadUrl: 'https://www.google.com', date_modified: 'May 8, 2020'
        }]
    }, {
        id: 103,
        name: 'Cellosaurus Ontology',
        redirectionUrl: 'https://web.expasy.org/cellosaurus/',
        history_BACK: [{
            version: '20200503-0518', downloadUrl: 'https://www.google.com', date_modified: 'May 9, 2020'
        }, {
            version: '20200503-0518', downloadUrl: 'https://www.google.com', date_modified: 'May 10, 2020'
        }]
    }, {
        id: 104,
        name: 'MONDO Disease Ontology',
        redirectionUrl: 'https://bioportal.bioontology.org/ontologies/MONDO',
        history_BACK: [{
            version: '20200503-1518', downloadUrl: 'https://www.google.com', date_modified: 'May 11, 2020'
        }, {
            version: '20200503-2518', downloadUrl: 'https://www.google.com', date_modified: 'May 12, 2020'
        }]
    }, {
        id: 105,
        name: 'Human Phenotype Ontology',
        redirectionUrl: 'https://bioportal.bioontology.org/ontologies/HP'
    }, {
        id: 106,
        name: 'NCBI ClinVar',
        redirectionUrl: 'https://www.ncbi.nlm.nih.gov/clinvar/'
    }, {
        id: 107,
        name: 'National Cancer Institute Thesaurus',
        redirectionUrl: 'https://bioportal.bioontology.org/ontologies/NCIT'
    }, {
        id: 108,
        name: 'Sequence Ontology',
        redirectionUrl: 'https://bioportal.bioontology.org/ontologies/SO'
    }, {
        id: 109,
        name: 'Developmental Epoch Ontology',
        redirectionUrl: null
    }, {
        id: 110,
        name: 'Gene Ontology',
        redirectionUrl: 'https://bioportal.bioontology.org/ontologies/GO'
    }],
    'attributeTables_BACKUP': [{
        id: 105,
        name: 'Data Collection Attributes',
        history: [{
            version: '20200503-0519', downloadUrl: 'https://www.google.com', date_modified: 'May 3, 2020'
        }, {
            version: '20200503-0518', downloadUrl: 'https://www.google.com', date_modified: 'May 5, 2020'
        }, {
            version: '20191222-0722', downloadUrl: 'https://www.google.com', date_modified: 'May 6, 2020'
        }]
    }, {
        id: 106,
        name: 'Dataset Attributes',
        history: [{
            version: '20200705-1234', downloadUrl: 'https://www.google.com', date_modified: 'May 7, 2020'
        }, {
            version: '20200705-1235', downloadUrl: 'https://www.google.com', date_modified: 'May 8, 2020'
        }]
    }, {
        id: 107,
        name: 'Sample Attributes',
        history: [{
            version: '20200503-0518', downloadUrl: 'https://www.google.com', date_modified: 'May 9, 2020'
        }, {
            version: '20200503-0518', downloadUrl: 'https://www.google.com', date_modified: 'May 10, 2020'
        }]
    }],
    'analyteMappingTables': [
        { id: 131, name: 'Microarray Mapping Tables' },
        { id: 132, name: 'Organism Mapping Tables' }
    ],
    'geneSetTables': [
        { id: 131, name: 'Gene Sets', date_modified: 'July 5, 2020' }
    ],
    'sampleAttributesBACKUP': [
        { id: 1001, sample_attribute: 'Sample source', type: 'Discreet text value', examples: 'Surgical resection', required: 'Yes', indexed: 'Yes', possible_values: '', description: 'Nulla vitae elit libero, a pharetra' },
        { id: 1002, sample_attribute: 'Sample source', type: 'Discreet text value', examples: 'Surgical resection', required: 'No', indexed: 'Yes', possible_values: '', description: 'Nulla vitae elit libero, a pharetra' },
        { id: 1003, sample_attribute: 'Sample source', type: 'Discreet text value', examples: 'Surgical resection', required: 'No', indexed: 'Yes', possible_values: '', description: 'Nulla vitae elit libero, a pharetra' },
        { id: 1004, sample_attribute: 'Sample source', type: 'Discreet text value', examples: 'Surgical resection', required: 'No', indexed: 'Yes', possible_values: '', description: 'Nulla vitae elit libero, a pharetra' },
        { id: 1005, sample_attribute: 'Sample source', type: 'Discreet text value', examples: 'Surgical resection', required: 'Yes', indexed: 'No', possible_values: '', description: 'Nulla vitae elit libero, a pharetra' },
        { id: 1006, sample_attribute: 'Sample source', type: 'Discreet text value', examples: 'Surgical resection', required: 'Yes', indexed: 'No', possible_values: '', description: 'Nulla vitae elit libero, a pharetra' },
        { id: 2001, sample_attribute: 'Sample source', type: 'Discreet text value', examples: 'Surgical resection', required: 'Yes', indexed: 'Yes', possible_values: '', description: 'Nulla vitae elit libero, a pharetra' },
        { id: 2002, sample_attribute: 'Sample source', type: 'Discreet text value', examples: 'Surgical resection', required: 'No', indexed: 'Yes', possible_values: '', description: 'Nulla vitae elit libero, a pharetra' },
        { id: 2003, sample_attribute: 'Sample source', type: 'Discreet text value', examples: 'Surgical resection', required: 'No', indexed: 'Yes', possible_values: '', description: 'Nulla vitae elit libero, a pharetra' },
        { id: 2004, sample_attribute: 'Sample source', type: 'Discreet text value', examples: 'Surgical resection', required: 'No', indexed: 'Yes', possible_values: '', description: 'Nulla vitae elit libero, a pharetra' },
        { id: 2005, sample_attribute: 'Sample source', type: 'Discreet text value', examples: 'Surgical resection', required: 'Yes', indexed: 'No', possible_values: '', description: 'Nulla vitae elit libero, a pharetra' },
        { id: 2006, sample_attribute: 'Sample source', type: 'Discreet text value', examples: 'Surgical resection', required: 'Yes', indexed: 'No', possible_values: '', description: 'Nulla vitae elit libero, a pharetra' },
        { id: 1007, sample_attribute: 'Sample source', type: 'Discreet text value', examples: 'Surgical resection', required: 'Yes', indexed: 'No', possible_values: '', description: 'Nulla vitae elit libero, a pharetra' },
        { id: 1008, sample_attribute: 'Sample source', type: 'Discreet text value', examples: 'Surgical resection', required: 'Yes', indexed: 'Yes', possible_values: '', description: 'Nulla vitae elit libero, a pharetra' },
        { id: 1009, sample_attribute: 'Sample source', type: 'Discreet text value', examples: 'Surgical resection', required: 'Yes', indexed: 'Yes', possible_values: '', description: 'Nulla vitae elit libero, a pharetra' },
        { id: 1010, sample_attribute: 'Sample source', type: 'Discreet text value', examples: 'Surgical resection', required: 'Yes', indexed: 'Yes', possible_values: '', description: 'Nulla vitae elit libero, a pharetra' }
    ],
    'microArray': [
        { id: 101, name: 'hgu133plus2', version: '1920130', platform: 'hgu133plus2', organism: 'Human', key: 'Probe ID', date_uploaded: 'Oct 12, 2020', downlod_url: 'google.com' },
        { id: 102, name: 'hgu133', version: '1920130', platform: 'hgu133', organism: 'Human', key: 'ENSEMBL ID', date_uploaded: 'Oct 12, 2020', downlod_url: 'google.com' },
        { id: 103, name: 'hgu165', version: '1920130', platform: 'hgu165', organism: 'Human', key: 'ENTREZ ID', date_uploaded: 'Oct 12, 2020', downlod_url: 'google.com' },
        { id: 104, name: 'hgu122plus1', version: '1920130', platform: 'hgu122plus1', organism: 'Human', key: 'Probe ID', date_uploaded: 'Oct 12, 2020', downlod_url: 'google.com' },
        { id: 105, name: 'hgu132plus2', version: '1920130', platform: 'hgu132plus2', organism: 'Human', key: 'Probe ID', date_uploaded: 'Oct 12, 2020', downlod_url: 'google.com' }
    ],
    'nonMicroArray': [
        { id: 101, name: 'Human-ENSEMBL2121', version: '1920130', platform: 'hgu133plus2', organism: 'Human', key: 'ENSEMBL ID', date_uploaded: 'Oct 12, 2020', downlod_url: 'google.com' },
        { id: 102, name: 'Mouse', version: '1920130', platform: 'hgu133', organism: 'Mouse', key: 'ENTREZ ID', date_uploaded: 'Oct 12, 2020', downlod_url: 'google.com' },
        { id: 103, name: 'Human', version: '1920130', platform: 'hgu165', organism: 'Human', key: 'ENSEMBL ID', date_uploaded: 'Oct 12, 2020', downlod_url: 'google.com' },
        { id: 104, name: 'XYZ', version: '1920130', platform: 'hgu122plus1', organism: 'Human', key: 'ENSEMBL ID', date_uploaded: 'Oct 12, 2020', downlod_url: 'google.com' },
        { id: 105, name: 'XYZ', version: '1920130', platform: 'hgu132plus2', organism: 'Human', key: 'ENSEMBL ID', date_uploaded: 'Oct 12, 2020', downlod_url: 'google.com' }
    ],
    'mappingTables': [
        { id: 101, name: 'XYZ', version: '1920130', key: 'ENSEMBL ID', value: 'ALIAS ID', date_modified: 'Oct 12, 2020', downlod_url: 'google.com' },
        { id: 102, name: 'XYZ', version: '1920130', key: 'ENSEMBL ID', value: 'ENTREZ ID', date_modified: 'Oct 12, 2020', downlod_url: 'google.com' },
        { id: 103, name: 'XYZ', version: '1920130', key: 'ENSEMBL ID', value: 'ENSEMBL ID', date_modified: 'Oct 12, 2020', downlod_url: 'google.com' },
        { id: 104, name: 'XYZ', version: '1920130', key: 'ENSEMBL ID', value: 'ENSEMBL ID', date_modified: 'Oct 12, 2020', downlod_url: 'google.com' },
        { id: 105, name: 'XYZ', version: '1920130', key: 'ENSEMBL ID', value: 'ENSEMBLPORT ID', date_modified: 'Oct 12, 2020', downlod_url: 'google.com' }
    ],
    'geneSet': [
        { id: 101, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 102, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 103, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 104, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 105, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 106, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 107, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 108, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 109, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 110, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 111, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 112, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 113, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 114, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 115, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 116, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 117, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 118, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 119, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' },
        { id: 120, set_id: 'MOSET1', set_name: 'ABA_ASTROCYTES', download_url: 'google.com', category: 'CellType', set_size: '38', species: 'Mus musculus', pub_med_id: '17151600', description: 'Astrocyte markers based on genome-wide mouse ISH (Table S1)', date_modified: 'Feb 10, 2019', uploaded_by: 'Oldham M.' }
    ],
    'dsFiles': [
        { id: 101, platform: 'Module Dataset', ds_version: 'A.1.1', transformations: 'Dataset', quantification_scale: '1.2', notes: 'Note one', upload_date: 'Nov 30, 2018' },
        { id: 102, platform: 'Module Eigengenes', ds_version: 'A.1.2', transformations: 'RDP', quantification_scale: '1.2', notes: 'Note two', upload_date: 'Nov 30, 2018' },
        { id: 103, platform: 'Module Eigengenes Correlations', ds_version: 'A.1.3', transformations: 'MDC', quantification_scale: '1.2', notes: 'Note three', upload_date: 'Nov 30, 2018' },
        { id: 104, platform: 'Module Eigengenes Correllelogram', ds_version: 'A.1.1.1', transformations: 'RDP OR', quantification_scale: '1.2', notes: 'Note four', upload_date: 'Nov 30, 2018' },
        { id: 105, platform: 'Module Snapshots', ds_version: 'A.1.1.2', transformations: 'RDP OR QN', quantification_scale: '1.2', notes: 'Note five', upload_date: 'Nov 30, 2018' },
        { id: 106, platform: 'Module Statistics', ds_version: 'A.1.1.3', transformations: 'RDP OR', quantification_scale: '1.2', notes: 'Note six', upload_date: 'Nov 30, 2018' },
        { id: 107, platform: 'Module Dataset', ds_version: 'A.1.1.1.1', transformations: 'RDP QN', quantification_scale: '1.2', notes: 'Note seven', upload_date: 'Nov 30, 2018' }
    ],
    // 'sampleSearchQueries': sampleSearchQueries
};

export const CSV_GRID_DATA = {
    "Index": {
        "0": 1,
        "1": 2,
        "2": 3,
        "3": 4,
        "4": 5,
        "5": 6,
        "6": 7,
        "7": 8,
        "8": 9,
        "9": 10
    },
    "PROBEID": {
        "0": "1007_s_at",
        "1": "1053_at",
        "2": "117_at",
        "3": "121_at",
        "4": "1255_g_at",
        "5": "1294_at",
        "6": "1316_at",
        "7": "1320_at",
        "8": "1405_i_at",
        "9": "1431_at"
    },
    "SYMBOL": {
        "0": "DDR1",
        "1": "RFC2",
        "2": "HSPA6",
        "3": "PAX8",
        "4": "GUCA1A",
        "5": "UBA7",
        "6": "THRA",
        "7": "PTPN21",
        "8": "CCL5",
        "9": "CYP2E1"
    },
    "ModSeed": {
        "0": null,
        "1": null,
        "2": "blue",
        "3": null,
        "4": null,
        "5": null,
        "6": null,
        "7": null,
        "8": null,
        "9": null
    },
    "MeanExpr": {
        "0": 12.1835975707,
        "1": 10.572561976,
        "2": 10.5203804142,
        "3": 10.8324433912,
        "4": 10.3870617065,
        "5": 10.5258317828,
        "6": 10.4506916679,
        "7": 10.3863689473,
        "8": 10.3903475778,
        "9": 10.4194246684
    },
    "MeanExprPercentile": {
        "0": 89.1113665832,
        "1": 77.3281816393,
        "2": 76.9465234096,
        "3": 79.2289656997,
        "4": 75.9714245388,
        "5": 76.9863949586,
        "6": 76.4368168652,
        "7": 75.9663576672,
        "8": 75.9954575456,
        "9": 76.2081286601
    },
    "TopModPosBC_7.83e.08": {
        "0": null,
        "1": "green",
        "2": "blue",
        "3": null,
        "4": "turquoise",
        "5": "brown",
        "6": "pink",
        "7": "magenta",
        "8": "brown",
        "9": "greenyellow"
    },
    "TopModPosFDR_0.0861": {
        "0": "black",
        "1": "green",
        "2": "blue",
        "3": null,
        "4": "turquoise",
        "5": "brown",
        "6": "pink",
        "7": "magenta",
        "8": "brown",
        "9": "greenyellow"
    },
    "TopModNegBC_7.83e.08": {
        "0": "turquoise",
        "1": "pink",
        "2": "greenyellow",
        "3": null,
        "4": null,
        "5": "pink",
        "6": "blue",
        "7": "pink",
        "8": null,
        "9": "blue"
    },
    "TopModNegFDR_0.0861": {
        "0": "turquoise",
        "1": "pink",
        "2": "greenyellow",
        "3": "midnightblue",
        "4": "salmon",
        "5": "pink",
        "6": "blue",
        "7": "pink",
        "8": "pink",
        "9": "blue"
    }
};

export const COVARIATION_ORDER = [
    'Module Eigengene Network',
    'Module Eigengene Correlogram',
    'Module Snapshots',
    'Module Statistics',
    'Module Statistics',
    'Module Eigengenes',
    'kME Table Metadata',
    'EnrichmentAnalysis'
];

export const COVARIATION_FILE_TYPE_ORDER = [
    'pdf',
    'csv'
];