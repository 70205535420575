export const selectFormInput = {
    label: '',
    description: '',
    elementType: 'select',
    elementConfig: {
        type: 'select',
        placeholder: '',
        disabled: false
    },
    value: '',
    validation: {
        required: false
    },
    valid: false,
    touched: false
};

export const listData = {
    visibilityField: ['Private', 'Public', 'Lab'],
    collaboratorField: []
};

export const commentsArray = [{
    id: 1001,
    message: "Aenean lacinia bibendum nulla sed consectetur. Cras mattis consectetur purus sit amet fermentum. Donec id elit non mi porta gravida at eget metus. Nullam id dolor id nibh ultricies vehicula ut id elit. Vestibulum id ligula porta felis euismod semper. Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Cras mattis consectetur purus sit amet fermentum.",
    isReplyOn: false,
    history: "3 days ago",
    user: "Michael Oldham"
}, {
    id: 1002,
    message: "Etiam porta sem malesuada magna mollis euismod. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum donec id elit non mi porta gravida.",
    isReplyOn: false,
    history: "8 days ago",
    user: "Kevin Kelley"
}];

export const repliesArray = [{
    id: 1101,
    message: "Aenean lacinia bibendum nulla sed consectetur. Nullam id dolor id nibh ultricies vehicula ut id elit. Vestibulum id ligula porta felis euismod semper. Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.",
    isReplyOn: false,
    history: "3 days ago",
    user: "Michael Oldham"
}, {
    id: 1102,
    message: "Etiam porta sem malesuada magna mollis euismod. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum donec id elit non mi porta gravida.",
    isReplyOn: false,
    history: "8 days ago",
    user: "Kevin Kelley"
}];
